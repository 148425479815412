export default {
    colors: {
      text: "#ea1313",
      background: "#f5ffe4",
      primary: "tomato",
    },
    fonts: {
      body: "Georgia, serif",
      heading: "system-ui, sans-serif",
    },
  }